<template>
  <div>
    <div class="row g-2">
      <div class="col-12">
        <div class="card bg-transparent-50 overflow-hidden">
          <div class="col-sm-auto d-flex align-items-center">
            <img class="ms-n2" src="assets/img/illustrations/crm-bar-chart.png" alt="" width="90" />
            <div>
              <h6 class="text-primary fw-bold mb-0">
                {{ getTimegreetings() }}
                <span class="text-info fw-medium">{{ user.first_name }}!</span>
              </h6>
              <!-- <p class="fs--1">Here’s what happening with your store today</p> -->
            </div>
            <img class="ms-n4 d-md-none d-lg-block" src="assets/img/illustrations/crm-line-chart.png" alt=""
              width="150" />
          </div>
          <!-- <div
                class="bg-holder d-none d-md-block bg-card z-index-1"
                style="
                  background-image: url('../assets/img/illustrations/crm-bar-chart.png');
                  background-position: right bottom;
                  z-index: -1;
                "
              ></div> -->
        </div>
      </div>

      <!-- <div>
            <div class="alert alert-primary my-2" role="alert">
              <p>
                Your customers can now pay you directly via M-Pesa Paybill No. <span class="fw-bold fs-bold font-xl">4114541</span> and
                Account No. <span class="fw-bold fs-bold font-xl">{{ user.company.unique_id }}</span>. We're working to
                expand our payment options around Africa. Stay tuned for more updates!
              </p>
            </div>
          </div> -->

      <div class="col-lg-12">

        <div class="row g-2">
          <div class="col-6 col-sm-2" role="button" @click="goToProducts">
            <div class="card overflow-hidden border-top border-bottom border-3 border-primary">
              <div class="card-body position-relative" :key="daily_analysis.products">
                <h6>
                  Products
                </h6>
                <count-up class="display-4 fs-3 fw-normal font-sans-serif text-primary" :end-val="daily_analysis.products"
                  :start-val="0"></count-up>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-2" role="button" @click="goToOrders">
            <div class="card overflow-hidden border-top border-bottom border-3 border-success">
              <div class="card-body position-relative" :key="daily_analysis.sales">
                <h6>
                  Sales (KES)
                </h6>
                <count-up class="display-4 fs-3 fw-normal font-sans-serif text-success" :end-val="daily_analysis.sales"
                  :start-val="0"></count-up>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-2" role="button" @click="goToUsers">
            <div class="card overflow-hidden border-top border-bottom border-3 border-warning">
              <div class="card-body position-relative" :key="daily_analysis.users">
                <h6>
                  Users
                </h6>
                <count-up class="display-4 fs-3 fw-normal font-sans-serif text-warning" :end-val="daily_analysis.users"
                  :start-val="0"></count-up>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-2" role="button" @click="goToCustomers">
            <div class="card overflow-hidden border-top border-bottom border-3 border-info">
              <div class="card-body position-relative" :key="daily_analysis.customers">
                <h6>
                  Customers
                </h6>
                <count-up class="display-4 fs-3 fw-normal font-sans-serif text-info" :end-val="daily_analysis.customers"
                  :start-val="0"></count-up>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-2" role="button" @click="goToConfig">
            <div class="card overflow-hidden border-top border-bottom  border-3 border-secondary">
              <div class="card-body position-relative" :key="daily_analysis.users">
                <h6>
                  Settings
                </h6>
                <count-up class="display-4 fs-3 fw-normal font-sans-serif text-secondary" :end-val="daily_analysis.users"
                  :start-val="0"></count-up>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-2" role="button" @click="goToPOS">
            <div class="card overflow-hidden border-top   border-bottom border-3 border-primary">
              <div class="card-body position-relative" :key="daily_analysis.users">
                <h6>
                  Invoices
                </h6>
                <count-up class="display-4 fs-3 fw-normal font-sans-serif text-primary" :end-val="daily_analysis.users"
                  :start-val="0"></count-up>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="card overflow-hidden" style="min-width: 12rem">
              <div class="bg-holder bg-card" style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                    "></div>
              <!--/.bg-holder-->
              <div class="card-body position-relative" :key="daily_analysis.customers">
                <h6>
                  Account Balance<span class="badge badge-soft-success rounded-pill ms-2">All</span>
                </h6>
                <count-up class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-success"
                  :end-val="daily_analysis.account_balance" :start-val="0"></count-up>
                <a class="fw-semi-bold fs--1 text-nowrap" @click="$router.push('/sales')" role="button">See all<span
                    class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card overflow-hidden" style="min-width: 12rem">
              <div class="bg-holder bg-card" style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                    "></div>
              <!--/.bg-holder-->
              <div class="card-body position-relative" :key="daily_analysis.customers">
                <h6>
                  Days Profit<span class="badge badge-soft-warning rounded-pill ms-2">All</span>
                </h6>
                <count-up class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning"
                  :end-val="daily_analysis.customers" :start-val="0"></count-up>
                <a class="fw-semi-bold fs--1 text-nowrap" @click="goToCustomers" role="button">See all<span
                    class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="card overflow-hidden" style="min-width: 12rem">
              <div class="bg-holder bg-card" style="
                      background-image: url('../assets/img/icons/spot-illustrations/corner-2.png');
                    "></div>
              <!--/.bg-holder-->
              <div class="card-body position-relative">
                <h6>
                  Closed Orders
                  <span class="badge badge-soft-info rounded-pill ms-2">{{
                    weekly_analysis.closed_orders > 0
                    ? Math.round(
                      (weekly_analysis.closed_orders /
                        weekly_analysis.total_orders) *
                      100
                    )
                    : 0
                  }}%</span>
                </h6>
                <count-up class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info"
                  :end-val="weekly_analysis.closed_orders" :start-val="0"></count-up>

                <a class="fw-semi-bold fs--1 text-nowrap" @click="goToOrders" role="button">All orders<span
                    class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card h-md-100 ecommerce-card-min-width">
              <div class="card-header pb-0">
                <h6 class="mb-0 mt-2 d-flex align-items-center">
                  Weekly Sales<span class="ms-1 text-400" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Calculated according to last week's sales"><span class="far fa-question-circle"
                      data-fa-transform="shrink-1"></span></span>
                </h6>
              </div>
              <div class="card-body d-flex flex-column justify-content-end">
                <div class="row">
                  <div class="col">
                    <count-up class="display-4 fs-3 mb-2 fw-normal font-sans-serif text-success"
                      :end-val="weekly_analysis.sales" :start-val="0">
                      <template #prefix>
                        <span style="me-2">KES</span>
                      </template>
                    </count-up>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card h-md-100 h-100">
              <div class="card-body" :key="chart_key">
                <div class="row h-100 justify-content-between g-0">
                  <h6 class="mt-1">Users Market Share</h6>
                  <div class="fs--2 mt-3" v-if="weekly_analysis">
                    <vue-echarts :option="market_share_chart_option" style="height: 350px; width: 100%"
                      ref="market-share-chart" id="market-share-chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card h-md-100 h-100">
              <div class="card-body" :key="chart_key">
                <div class="row h-100 justify-content-between g-0">
                  <h6 class="mt-1">Sales Analysis</h6>
                  <div class="fs--2 mt-3" v-if="weekly_analysis">
                    <vue-echarts :option="sales_chart_option" style="height: 350px; width: 100%" ref="chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card h-md-100 h-100">
              <div class="card-body" :key="chart_key">
                <div class="row h-100 justify-content-between g-0">
                  <h6 class="mt-1">Best Seller</h6>
                  <div class="fs--2 mt-3" v-if="weekly_analysis">
                    <vue-echarts :option="best_seller_option" style="height: 350px; width: 100%" ref="chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card h-md-100 h-100">
              <div class="card-body" :key="chart_key">
                <div class="row h-100 justify-content-between g-0">
                  <h6 class="mt-1">Stock Value by Cost & Price</h6>
                  <div class="fs--2 mt-3" v-if="stock_analysis">
                    <vue-echarts :option="stock_value_chart_option" style="height: 350px; width: 100%" ref="chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="authentication-modal" tabindex="-1" role="dialog"
      aria-labelledby="authentication-modal-label" aria-hidden="true">
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div class="modal-header px-5 position-relative modal-shape-header bg-shape">
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">Register</h4>
              <p class="fs--1 mb-0 text-white">Please create your free Falcon account</p>
            </div>
            <button class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label><input class="form-control" type="text"
                  autocomplete="on" id="modal-auth-name" />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email">Email address</label><input class="form-control"
                  type="email" autocomplete="on" id="modal-auth-email" />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password">Password</label><input class="form-control"
                    type="password" autocomplete="on" id="modal-auth-password" />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password">Confirm Password</label><input
                    class="form-control" type="password" autocomplete="on" id="modal-auth-confirm-password" />
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="modal-auth-register-checkbox" /><label
                  class="form-label" for="modal-auth-register-checkbox">I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label>
              </div>
              <div class="mb-3">
                <button class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a class="btn btn-outline-google-plus btn-sm d-block w-100" href="index.html#"><span
                    class="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span>
                  google</a>
              </div>
              <div class="col-sm-6">
                <a class="btn btn-outline-facebook btn-sm d-block w-100" href="index.html#"><span
                    class="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span>
                  facebook</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import OrdersService from "../services/orders.service";
import ReportService from "../services/report.service";
import CustomerService from "../services/customer.service";

// echarts
import { VueEcharts } from "vue3-echarts";

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    VueEcharts,
    EasyDataTable,
  },
  data() {
    return {
      editedItem: {},
      selected: [],
      selected_items: "",
      chart_key: 1,
      refreshed: 0,
      daily_analysis: [],
      weekly_analysis: [],
      stock_analysis: [],
      stock_value_chart_option: {},
      market_share_chart_option: {},
      sales_chart_option: {},
      best_seller_option: {},
      resources: [],
      customers: [],
      formData: {
        from: moment(new Date()).format("DD-MM-YYYY"),
        to: moment(new Date()).format("DD-MM-YYYY"),
      },
      config: {
        dateFormat: "M j, Y",
      },
      headers: [
        { text: "Ticket", value: "id", sortable: true, width: "10" },
        { text: "Date", value: "date", sortable: true },
        { text: "Served by", value: "owner", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Customer Detail", value: "customer", sortable: true },
        { text: "Due", value: "DUE_AMOUNT", sortable: true },
        { text: "Paid", value: "PAID_AMOUNT", sortable: true },
        { text: "Location", value: "location", align: "end", width: "20" },
        {
          text: "Amount",
          value: "TOTAL_PRICE",
          width: 120,
          align: "right",
          sortable: true,
        },
        { text: "Action", value: "action" },
      ],
    };
  },
  watch: {
    weekly_analysis: {
      handler(newVal, oldVal) {
        this.chart_key += 1;
      },
      deep: true,
    },
  },
  async created() {
    this.getDailyAnalysis();
    this.getBestSeller();
    this.getStockAnalysis();
    this.getWeeklyAnalysis();
    this.getCustomers();
    // this.getAllOrders();
    // this.getOpenOrders();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    updateSelected(e) {
      let item_id = event.target.value;
      if (event.target.checked) {
        this.selected.push(item_id);
      } else {
        this.selected = this.selected.filter((el) => el != item_id);
      }
      this.selected_items = this.selected.join(",");
    },
    removeItem(item) {
      this.selected = this.selected.filter((el) => el != item);
      console.log(this.selected);
    },
    mergeConfirm() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        ticket_ids: this.selected_items,
      };
      TicketService.mergeTicket(formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success("Tickets merged successfully!", {
              autoClose: 3000,
            });
            this.getOpenOrders();
            this.selected = [];
            this.selected_items = "";
            this.$store.commit("SET_LOADING", false);
          } else {
            toast.error("Error merging tickets!", {
              autoClose: 3000,
            });
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    sortBy(field_name) {
      console.log("Sorting...", field_name);
      switch (field_name) {
        case "amount":
          this.resources = this.resources.sort((a, b) =>
            this.sort_order
              ? a.TOTAL_PRICE - b.TOTAL_PRICE
              : b.TOTAL_PRICE - a.TOTAL_PRICE
          );
          break;
        case "date":
          this.resources = this.resources.sort((a, b) =>
            this.sort_order
              ? new Date(a.CREATE_DATE).getTime() - new Date(b.CREATE_DATE).getTime()
              : new Date(b.CREATE_DATE).getTime() - new Date(a.CREATE_DATE).getTime()
          );
          console.log("here sorting");
          break;

        default:
          break;
      }
      this.sort_order = !this.sort_order;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    voidTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.id;
      let proceed = confirm(
        "Are you sure you want to void? This action cannot be undone!"
      );
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.voidTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.preview_receipt_modal = false;
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket voided!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error voiding ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    giftTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.id;
      let proceed = confirm(
        "Are you sure you want to gift? This action cannot be undone!"
      );
      console.log("method");
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.giftTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket gifted!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error gifting Ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    closeTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.id;
      let proceed = confirm(
        "Are you sure you want to close? This action cannot be undone!"
      );
      console.log("method");
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.closeTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket closed!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error closing ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    goToPOS() {
      this.$router.push("/pos");
    },
    goToOrders() {
      this.$router.push("/pos-open-order");
    },
    goToProducts() {
      this.$router.push("/menu-item");
    },
    goToUsers() {
      this.$router.push("/users");
    },
    goToConfig() {
      this.$router.push("/configuration");
    },
    goToCustomers() {
      this.$router.push("/customers");
    },
    goToPOS() {
      this.$router.push("/pos");
    },
    displayStatus(status) {
      let badge = "";
      switch (status) {
        case "OPEN":
          badge = "success lighten-1 text-capitalize success--text";
          break;
        default:
          badge = "secondary lighten-1 text-capitalize  secondary--text";
          break;
      }
      return badge;
    },
    onProgress(e) {
      console.log(e);
    },
    hasStartedGeneration() { },
    hasGenerated(e) {
      console.log(e);
    },
    payReceipt(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      let amount = prompt("Enter Amount");
      if (amount > 0) {
        this.editedItem.amount = amount;
        this.editedItem.mode = "CASH";
        this.editedItem.ticket_id = this.editedItem.id;
        TicketService.payTicket(this.editedItem).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket updated!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error updating ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
            this.$router.go("/");
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.$store.commit("SET_LOADING", false);
      }
    },
    payReceiptMpesa(selected_item) {
      this.editedItem = selected_item;
      let amount = prompt("Enter Amount");
      if (amount > 0) {
        this.$store.commit("SET_LOADING", true);
        this.editedItem.amount = amount;
        this.editedItem.mode = "MPESA";
        this.editedItem.ticket_id = this.editedItem.id;
        TicketService.payTicket(this.editedItem).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket updated!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              this.$store.commit("SET_LOADING", false);
              toast.error("Error updating ticket!", {
                autoClose: 2000,
              });
            }
            this.$router.go("/");
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.$store.commit("SET_LOADING", false);
      }
    },
    changeCustomer(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      this.editedItem.customer_id = this.selected_customer;
      this.editedItem.ticket_id = this.editedItem.id;
      TicketService.change(this.editedItem).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Ticket updated!", {
              autoClose: 2000,
            });
          } else {
            console.log(response.data.error);
            toast.error("Error updating ticket!", {
              autoClose: 2000,
            });
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    statusChanged() {
      let status = this.order_status;
      switch (status) {
        case 0:
          // this.getAllOrders(); deactivated for datas
          this.getOpenOrders();
          break;
        case 1:
          this.getOpenOrders();
          break;
        case 2:
          this.getClosedOrders();
          break;
        case 3:
          this.getVoidedOrders();
          break;
        default:
          this.getAllOrders();
          break;
      }
    },

    printReceipt(selected_item) {
      console.log(selected_item);
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      console.log(this.editedItem);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.name;
      this.formData.date = this.editedItem.created_at;
      this.formData.reference = "BL" + this.editedItem.id;
      this.formData.created_by = this.editedItem.owner.first_name;
      this.formData.address_line_1 = this.user.company.address_line1;
      this.formData.address_line_2 = this.user.company.address_line2;
      this.formData.address_line_3 = this.user.company.address_line3;
      this.formData.ticket_items = JSON.stringify(this.editedItem.items);
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.sub_total;
      this.formData.paid = this.editedItem.paid_amount;
      this.formData.due = this.editedItem.due_amount;
      this.formData.tax = this.editedItem.total_tax;
      this.formData.total_amount = this.editedItem.total_price;
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printReceipt(this.formData).then(
        (response) => {
          toast.success("Receipt printed!", {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
            (error.response && error.response.data) ||
            error.mesage ||
            error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    printBill(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      console.log(this.editedItem);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.name;
      this.formData.date = this.editedItem.created_at;
      this.formData.reference = "BL" + this.editedItem.id;
      this.formData.created_by = this.editedItem.owner.first_name;
      this.formData.address_line_1 = this.user.company.address_line1;
      this.formData.address_line_2 = this.user.company.address_line2;
      this.formData.address_line_3 = this.user.company.address_line3;
      this.formData.ticket_items = JSON.stringify(this.editedItem.items);
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.sub_total;
      this.formData.paid = this.editedItem.paid_amount;
      this.formData.due = this.editedItem.due_amount;
      this.formData.tax = this.editedItem.total_tax;
      this.formData.total_amount = this.editedItem.total_price;
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printBill(this.formData).then(
        (response) => {
          toast.success("Print success!", {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
            (error.response && error.response.data) ||
            error.mesage ||
            error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    printOrder(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      console.log(this.editedItem);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.name;
      this.formData.date = this.editedItem.created_at;
      this.formData.reference = "BL" + this.editedItem.id;
      this.formData.created_by = this.editedItem.owner.first_name;
      this.formData.address_line_1 = this.user.company.address_line1;
      this.formData.address_line_2 = this.user.company.address_line2;
      this.formData.address_line_3 = this.user.company.address_line3;
      this.formData.ticket_items = JSON.stringify(this.editedItem.items);
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.sub_total;
      this.formData.tax = this.editedItem.total_tax;
      this.formData.total_amount = this.editedItem.total_price;
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printOrder(this.formData).then(
        (response) => {
          toast.success("Print success!", {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
            (error.response && error.response.data) ||
            error.mesage ||
            error.toString(),
            { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    generateReport(name) {
      this.report_name = name;
      setTimeout(() => {
        var printable_content = document.getElementById("printable_content");
        var pdf = document.getElementById("printarea");
        pdf.innerHTML = printable_content.innerHTML;
        this.$refs.html2Pdf.generatePdf();
      }, 100);
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    getCustomers() {
      return CustomerService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.customers = response.data.data;
          } else {
            this.customers = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getAllOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.index().then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getOpenOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.open().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getClosedOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.closed().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getVoidedOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.voided().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.resources.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    previewReceiptModal(order) {
      this.editedItem = Object.assign({}, order);
      this.editedIndex = this.resources.indexOf(order);
      this.cart = Object.assign({}, order.order);
      this.totalValue = order.total_price;
      this.editedItem.ticket_id = order.id;
    },
    changeCustomerModal() {
      this.change_customer_modal = true;
    },
    viewItem(item) {
      this.editedIndex = this.resources.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getCustomers() {
      return CustomerService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.customers = response.data.data;
          } else {
            this.customers = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getOpenOrders() {
      this.$store.commit("SET_LOADING", true);
      return OrdersService.open().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    goToOrders() {
      this.$router.push("/pos-open-order");
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getStockAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.stockAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            console.group("dataset");
            let dataset = [
              {
                name: "Stock Value by Cost",
                value: response.data.data.stock_cost_value,
              },
              {
                name: "Stock Value by Price",
                value: response.data.data.stock_price_value,
              },
            ];
            this.stock_value_chart_option = {
              title: {
                text: "Stock Analysis",
                subtext: "All cost (Ksh)",
                left: "right",
              },
              tooltip: {
                trigger: "item",
              },
              legend: {
                orient: "vertical",
                left: "left",
              },
              series: [
                {
                  name: "Stock Analysis",
                  type: "pie",
                  radius: "60%",
                  data: dataset,
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            };
            console.log(this.stock_value_chart_option);
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
            (this.market_share_chart_option = {
              title: {
                text: "Market share",
                subtext: "No of tickets sold by user",
                left: "center",
              },
              tooltip: {
                trigger: "item",
              },
              legend: {
                orient: "vertical",
                left: "left",
              },
              series: [
                {
                  name: "Tickets sold",
                  type: "pie",
                  radius: "60%",
                  data: response.data.data.market_share,
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                },
              ],
            }),
              (this.sales_chart_option = {
                title: {
                  text: "Revenue Chart",
                  subtext: "Revenue collected this week",
                  left: "right",
                },
                xAxis: {
                  type: "category",
                  data: this.weekly_analysis.weekly_sales_days,
                },
                yAxis: {
                  type: "value",
                },
                series: [
                  {
                    data: this.weekly_analysis.weekly_sales,
                    type: "bar",
                    showBackground: true,
                    backgroundStyle: {
                      color: "rgba(180, 180, 180, 0.2)",
                    },
                  },
                ],
              });
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getBestSeller() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.bestSeller().then(
        (response) => {
          if (response.data.code == 200) {
            this.best_seller = response.data.data;
            let dataset = [];
            dataset[0] = ["Price", "Quantity", "Product"];
            Object.entries(response.data.data).forEach(([key, element]) => {
              dataset[parseInt(key) + 1] = [
                element.item_price,
                element.count,
                element.item_name,
              ];
            });
            this.best_seller_option = {
              dataset: {
                source: dataset,
              },
              grid: { containLabel: true },
              xAxis: { name: "Product Price" },
              yAxis: { type: "category" },
              visualMap: {
                orient: "horizontal",
                left: "center",
                min: 10,
                max: 100,
                text: ["High Price", "Low Price"],
                // Map the score column to color
                dimension: 0,
                inRange: {
                  color: ["#65B581", "#FFCE34", "#FD665F"],
                },
              },
              series: [
                {
                  type: "bar",
                  encode: {
                    // Map the "amount" column to X axis.
                    x: "Quantity",
                    // Map the "product" column to Y axis
                    y: "Product",
                  },
                },
              ],
            };
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
