<template>
  <div>

    <div class="card py-0" id="ordersTable">

      <div class="card-header pb-0 border-bottom">

        <h5 class="fs-0 ms-0 text-nowrap">Orders</h5>

      </div>

      <div class="card-body">

        <!-- Start new table  -->

        <div id="tableExample3">

          <div class="row justify-content-start">

            <div class="col-8 mb-3">

              <div class="dropdown float-left">

                <button class="btn btn-falcon-default text-600 btn-sm dropdown-toggle dropdown-caret-none" type="button"
                  id="email-filter" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true"
                  aria-expanded="false">

                  <span data-view-title="data-view-title">Select Tickets/Orders</span><span
                    class="fas fa-sort ms-2 fs--1"></span>

                </button>

                <div class="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="email-filter">

                  <a class="active dropdown-item d-flex justify-content-between" @click="getOpenOrders"
                    data-fc-view="dayGridMonth">Open Tickets<span class="icon-check"><span class="fas fa-check"
                        data-fa-transform="down-4 shrink-4"></span></span></a><a
                    class="dropdown-item d-flex justify-content-between" @click="getVoidedOrders"
                    data-fc-view="timeGridWeek">Void Tickets<span class="icon-check"><span class="fas fa-check"
                        data-fa-transform="down-4 shrink-4"></span></span></a>

                  <a class="dropdown-item d-flex justify-content-between" @click="getClosedOrders"
                    data-fc-view="timeGridWeek">Closed Tickets<span class="icon-check"><span class="fas fa-check"
                        data-fa-transform="down-4 shrink-4"></span></span></a>

                  <a class="dropdown-item d-flex justify-content-between" @click="getAllOrders"
                    data-fc-view="timeGridWeek">All Tickets<span class="icon-check"><span class="fas fa-check"
                        data-fa-transform="down-4 shrink-4"></span></span></a>

                </div>

              </div>




            </div>

            <div class="col-4">

              <button class="btn btn-sm btn-falcon-warning ms-auto me-1" @click="goToPOS">
                POS
              </button>
              <button :disabled="selected.length <= 1" class="btn btn-sm btn-falcon-danger" type="button"
                data-bs-toggle="modal" data-bs-target="#mergeModal">
                <span class="fas fa-object-group" data-fa-transform="shrink-3 down-2"></span><span
                  class="d-none d-sm-inline-block ms-1">Merge</span>
              </button>
              <button class="btn btn-falcon-default text-600 btn-sm dropdown-toggle dropdown-caret-none ms-2" type="button"
                id="email-filter" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true"
                aria-expanded="false">

                <span data-view-title="data-view-title">Showing</span><span class="fas fa-sort ms-2 fs--1"></span>

              </button>

              <div class="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="email-filter">

                <a v-for="(item, i) in showing" :key="i" class="dropdown-item d-flex justify-content-between"
                  :class="per_page == item ? 'active' : ''" @click="setPerPage(item)" data-fc-view="dayGridMonth">

                  Show {{ item }}

                  <span v-if="per_page == item" class="icon-check">

                    <span class="fas fa-check" data-fa-transform="down-4 shrink-4"></span></span>

                </a>

              </div>

            </div>

          </div>

          <div class="table-responsive scrollbar">

            <table class="table table-bordered table-striped fs--1 mb-0">

              <thead class="bg-200 text-900">

                <tr>

                  <th class="sort" data-sort="name">Ticket No.</th>

                  <th class="sort" data-sort="email">Date</th>

                  <th class="sort" data-sort="age">Served by</th>

                  <th class="sort" data-sort="age">Status</th>

                  <th class="sort" data-sort="age">Customer</th>

                  <th class="sort" data-sort="age">Due</th>

                  <th class="sort" data-sort="age">Paid</th>

                  <th class="sort" data-sort="age">Location</th>

                  <th class="sort" data-sort="age">Amount</th>

                  <th class="sort" data-sort="age">Action</th>

                </tr>

              </thead>

              <tbody class="list">

                <tr v-for="(item, i) in resources" :key="item.id">

                  <td>

                    <div class="form-check fs-0 mb-0 d-flex align-items-center">

                      <input class="form-check-input" type="checkbox" id="checkbox-0" :value="item.id"
                        @click="updateSelected" data-bulk-select-row="data-bulk-select-row" />

                      <span class="text-secondary ms-2">

                        <strong class="ml-1">{{ item.id }}</strong>

                      </span>

                    </div>

                  </td>

                  <td>{{ formatDate(item.create_date) }}</td>

                  <td>{{ item.owner.first_name }}</td>

                  <td>

                    <span v-if="item.status == 'DONE'" class="badge badge rounded-pill d-block badge-soft-secondary">On
                      Hold

                      <span class="ms-1 fas fa-ban" data-fa-transform="shrink-2"> </span>

                    </span>

                    <span v-if="item.status == 'VOID' || item.status == 'VOIDED'"
                      class="badge badge rounded-pill d-block badge-soft-danger">

                      Voided

                      <span class="ms-1 fas fa-stream" data-fa-transform="shrink-2">

                      </span>

                    </span>

                    <span v-if="item.status == 'OPEN'" class="badge badge rounded-pill d-block badge-soft-primary">

                      Open

                      <span class="ms-1 fas fa-redo" data-fa-transform="shrink-2"></span> </span><span
                      v-if="item.status == 'GIFT' || item.status == 'GIFTED'"
                      class="badge badge rounded-pill d-block badge-soft-info">

                      Gifted

                      <span class="ms-1 fas fa-redo" data-fa-transform="shrink-2"></span>

                    </span>

                    <span v-if="item.status == 'CLOSED'"
                      class="badge badge rounded-pill d-block badge-soft-success">Closed<span class="ms-1 fas fa-check"
                        data-fa-transform="shrink-2"></span></span>

                  </td>

                  <td>{{ item.customer.first_name }}</td>

                  <td>{{ formatToCurrency(item.due_amount) }}</td>

                  <td>{{ formatToCurrency(item.paid_amount) }}</td>

                  <td>{{ item.location.name }}</td>

                  <td>{{ formatToCurrency(item.total_price) }}</td>

                  <td>

                    <div class="dropdown font-sans-serif position-static">

                      <button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal" type="button"
                        id="order-dropdown-0" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true"
                        aria-expanded="false">

                        <span class="fas fa-ellipsis-h fs--1"></span>

                      </button>

                      <div class="dropdown-menu dropdown-menu-end border py-0" aria-labelledby="order-dropdown-0">

                        <div class="py-2">

                          <a class="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#edit-modal"
                            @click="editItem(item)">

                            Preview

                          </a>

                          <a @click="printReceipt(item)" class="dropdown-item text-primary" href="javascript:;">Print
                            Receipt</a>

                          <a role="button" class="dropdown-item text-warning" @click="printOrder(item)">Print Order</a>

                          <a class="dropdown-item text-info" role="button" @click="printBill(item)">Print Bill</a>

                          <a v-if="user.permissions.includes('done-ticket')" class="dropdown-item" role="button"
                            @click="closeTicket(item)">Close Ticket</a>

                          <div class="dropdown-divider"></div>

                          <a v-if="user.permissions.includes('void-ticket')" class="dropdown-item text-danger"
                            role="button" @click="voidTicket(item)">Void</a>

                          <a v-if="user.permissions.includes('gift-ticket')" class="dropdown-item text-success"
                            role="button" @click="giftTicket(item)">Gift</a>

                        </div>

                      </div>

                    </div>

                  </td>

                </tr>

              </tbody>

            </table>

          </div>

          <div class="d-flex justify-content-center mt-3">

            <pagination v-model="current_page" :records="records" :per-page="per_page" @paginate="myCallback" />

          </div>

        </div>

      </div>

    </div>

    <!-- Start of order preview modal  -->

    <div class="modal fade" id="edit-modal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="edit-modalLabel"
      aria-hidden="true">

      <div class="modal-dialog modal-lg mt-6" role="document">

        <div class="modal-content border-0">

          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">

            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
              aria-label="Close"></button>

          </div>

          <div class="modal-body p-0">

            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">

              <h4 class="mb-1" id="edit-itemLabel">Order Preview</h4>

              <p class="fs--2 mb-0">

                Added by

                <a class="link-600 fw-semi-bold" href="#!">{{

                  editedItem.owner.first_name

                }}</a>

              </p>

            </div>

            <div class="p-4">

              <div class="card mb-3">

                <div class="bg-holder d-none d-lg-block bg-card" style="
    
                        background-image: url('../assets/img/icons/spot-illustrations/corner-4.png');
    
                        opacity: 0.7;
    
                      "></div>

                <!--/.bg-holder-->

                <div class="card-body position-relative">

                  <div class="row">

                    <div class="col-6">

                      <h5>Order Details: #{{ editedItem.id }}</h5>

                      <p class="fs--1">

                        {{ formatDate(editedItem.create_date) }}

                      </p>

                      <div>

                        <strong class="me-2">Status: </strong>

                        <div class="badge rounded-pill badge-soft-success fs--2">

                          {{ editedItem.status }}

                          <span class="fas fa-check ms-1" data-fa-transform="shrink-2"></span>

                        </div>

                      </div>

                    </div>

                    <div class="col-3">

                      <ul class="nav flex-lg-column fs--1">

                        <li class="nav-item me-2 me-lg-0">

                          <a class="nav-link nav-link-card-details bg-success text-white"
                            @click="payReceiptMpesa(editedItem)" role="button"><span
                              class="fas fa-user me-2"></span>Receive MPESA</a>

                        </li>

                        <li class="nav-item me-2 me-lg-0">

                          <a class="nav-link nav-link-card-details bg-primary text-white" @click="payReceipt(editedItem)"
                            role="button"><span class="fas fa-tag me-2"></span>Receive CASH</a>

                        </li>

                      </ul>

                    </div>

                    <div class="col-3">

                      <ul class="nav flex-lg-column fs--1">

                        <li class="nav-item me-2 me-lg-0">

                          <a class="nav-link nav-link-card-details bg-info text-white" @click="printReceipt(editedItem)"
                            role="button"><span class="fas fa-print me-2"></span>Print Receipt</a>

                        </li>

                        <li class="nav-item me-2 me-lg-0">

                          <a class="nav-link nav-link-card-details bg-danger text-white" @click="closeTicket(editedItem)"
                            role="button"><span class="fa fa-align-left me-2"></span>Close Ticket

                          </a>

                        </li>

                      </ul>

                    </div>

                  </div>

                </div>

              </div>

              <div class="card mb-3">

                <div class="card-body">

                  <div class="row">

                    <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">

                      <h5 class="mb-3 fs-0">Billing Address</h5>

                      <h6 class="mb-2">

                        {{ editedItem.customer.first_name }} {{ editedItem.customer.last_name }}

                      </h6>

                      <p class="mb-1 fs--1">

                        {{ editedItem.customer.country }}, {{ editedItem.customer.city }}

                      </p>

                      <p class="mb-0 fs--1">

                        <strong>Email: </strong><a href="mailto:ricky@gmail.com">{{

                          editedItem.customer.email

                        }}</a>

                      </p>

                      <p class="mb-0 fs--1">

                        <strong>Phone: </strong><a :href="`tel:${editedItem.customer.mobile_no}`">{{

                          editedItem.customer.mobile_no

                        }}</a>

                      </p>

                    </div>

                    <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">

                      <h5 class="mb-3 fs-0">Shipping Address</h5>

                      <h6 class="mb-2">

                        {{ editedItem.customer.first_name }}

                        {{ editedItem.customer.last_name }}

                      </h6>

                      <p class="mb-0 fs--1">

                        {{

                          editedItem.delivery_address

                          ? editedItem.delivery_address

                          : "On site"

                        }}

                        <br />

                        {{

                          editedItem.customer_pickeup

                          ? editedItem.customer_pickeup

                          : "Walk in customer"

                        }}

                      </p>

                      <div class="text-500 fs--1">

                        {{

                          editedItem.delivery_charge

                          ? editedItem.delivery_charge

                          : "(Free Shipping)"

                        }}

                      </div>

                    </div>

                    <div class="col-md-6 col-lg-4">

                      <h5 class="mb-3 fs-0">Payment Method</h5>

                      <div class="d-flex">

                        <img class="me-3" src="assets/img/icons/mpesa.png" width="40" height="30" alt="" />

                        <div class="flex-1">

                          <h6 class="mb-0">

                            {{ editedItem.customer.first_name }}

                            {{ editedItem.customer.last_name }}

                          </h6>

                          <p class="mb-0 fs--1">

                            {{

                              editedItem.payment_method

                              ? editedItem.payment_method

                              : "(N/A)"

                            }}

                          </p>

                        </div>

                      </div>

                      <ul class="nav flex-lg-column fs--1 mt-3">

                        <li class="nav-item me-2 me-lg-0">

                          <a class="nav-link nav-link-card-details" href="#!"><span class="fas fa-user me-2"></span>Change
                            Customer</a>

                        </li>

                      </ul>

                    </div>

                  </div>

                </div>

              </div>

              <div class="card mb-3">

                <div class="card-body">

                  <div class="table-responsive fs--1">

                    <table class="table table-striped border-bottom">

                      <thead class="bg-200 text-900">

                        <tr>

                          <th class="border-0">Products</th>

                          <th class="border-0 text-center">Quantity</th>

                          <th class="border-0 text-end">Rate</th>

                          <th class="border-0 text-end">Amount</th>

                        </tr>

                      </thead>

                      <tbody>

                        <tr class="border-200" v-for="(prod, i) in editedItem.items" :key="i">

                          <td class="align-middle">

                            <h6 class="mb-0 text-nowrap">

                              {{ prod.item_name }}

                            </h6>

                          </td>

                          <td class="align-middle text-center">

                            {{ prod.item_quantity }}

                          </td>

                          <td class="align-middle text-end">

                            <!-- editedItem.retail_price -->

                            {{ formatToCurrency(prod.ws_price) }}

                            {{ formatToCurrency(prod.item_price) }}

                          </td>

                          <td class="align-middle text-end">

                            {{ formatToCurrency(prod.total_price) }}

                          </td>

                        </tr>

                      </tbody>

                    </table>

                  </div>

                  <div class="row g-0 justify-content-end">

                    <div class="col-auto">

                      <table class="table table-sm table-borderless fs--1 text-end">

                        <tr>

                          <th class="text-900 text-start">Sub Total:</th>

                          <td class="fw-semi-bold">

                            {{

                              editedItem.delivery_charge

                              ? formatToCurrency(

                                editedItem.total_price -

                                editedItem.delivery_charge -

                                getVAT(editedItem.total_price)

                              )

                              : formatToCurrency(

                                editedItem.total_price -

                                getVAT(editedItem.total_price)

                              )

                            }}

                          </td>

                        </tr>

                        <tr>

                          <th class="text-900 text-start">Shipping</th>

                          <td class="fw-semi-bold">

                            {{

                              editedItem.delivery_charge

                              ? formatToCurrency(editedItem.delivery_charge)

                              : 0.0

                            }}

                          </td>

                        </tr>

                        <tr class="">

                          <th class="text-900 text-start">Paid:</th>

                          <td class="fw-semi-bold">

                            {{ formatToCurrency(editedItem.paid_amount) }}

                          </td>

                        </tr>

                        <tr class="border-bottom text-start">

                          <th class="text-900">Due/Bal:</th>

                          <td class="fw-semi-bold">

                            {{ formatToCurrency(editedItem.due_amount) }}

                          </td>

                        </tr>

                        <tr class="border-top">

                          <th class="text-900 text-start">Total:</th>

                          <td class="fw-semi-bold">

                            {{ formatToCurrency(editedItem.total_price) }}

                          </td>

                        </tr>

                      </table>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

    <!-- End of order preview modal  -->



    <!-- Start of confirm merge modal  -->

    <div class="modal fade" id="mergeModal" tabindex="-1" role="dialog" aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">

        <div class="modal-content position-relative">

          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">

            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal"
              aria-label="Close"></button>

          </div>

          <div class="modal-body p-0">

            <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">

              <h4 class="mb-1">Confirm Merge</h4>

            </div>

            <div class="px-5 pb-0">

              <p class="text-center">

                Do you really want to merge the following tickets?

              </p>



              <button v-for="(element, i) in selected" :key="i" data-bs-toggle="tooltip" data-bs-placement="top"
                title="Click to remove" @click="removeItem(element)" class="btn btn-outline-warning me-1 mb-1"
                type="button">

                Ticket # {{ element }}

              </button>

            </div>

          </div>

          <div class="modal-footer">

            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">

              Close

            </button>

            <button class="btn btn-primary" type="button" data-bs-dismiss="modal" @click="mergeConfirm">

              Confirm

            </button>

          </div>

        </div>

      </div>

    </div>

    <!-- End of confirm merge modal  -->

  </div>
</template>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-background-color: #edf2f9;
  --easy-table-body-row-height: 60px;
  --easy-table-body-row-font-size: 12px;
}

.page-link {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
</style>

<script>
import moment from "moment";
import mixins from "../mixins/index";
import EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import AppBar from "@/components/AppBar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import VueHtml2pdf from "vue3-html2pdf";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrderService from "../services/orders.service";
import OrdersService from "../services/orders.service";
import CustomerService from "../services/customer.service";
import TicketService from "../services/ticket.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import Pagination from "v-pagination-3";

const now = new Date();

export default {
  mixins: [mixins],
  name: "TicketAnalysisReportView",
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    Pagination,
    VueHtml2pdf,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      type: "voided",
      showing: [25, 100, 200, 500, "All"],
      user: {},
      per_page: 25,
      current_page: 0,
      records: 0,
      editedItem: {
        name: "",
        customer: {
          first_name: "",
          mobile_no: "",
        },
        owner: {
          mobile_no: "",
        },
        delivery_charge: "",
        paid_amount: "",
        total_price: "",
        due_amount: "",
      },
      selected: [],
      selected_items: "",
      sort_order: 0,
      orders_table_key: 0,
      enable_download: false,
      preview_modal: false,
      printarea_key: 1,
      resources: [],
      customers: [],
      formData: {
        from: moment(new Date()).format("DD-MM-YYYY"),
        to: moment(new Date()).format("DD-MM-YYYY"),
      },
      config: {
        dateFormat: "M j, Y",
      },
      headers: [
        { text: "Ticket", value: "id", sortable: true, width: "10" },
        { text: "Date", value: "date", sortable: true },
        { text: "Served by", value: "owner", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Customer Detail", value: "customer", sortable: true },
        { text: "Due", value: "due_amount", sortable: true },
        { text: "Paid", value: "paid_amount", sortable: true },
        { text: "Location", value: "location", align: "end", width: "20" },
        {
          text: "Amount",
          value: "total_price",
          width: 120,
          align: "right",
          sortable: true,
        },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    this.getOpenOrders();
    this.getCustomers();
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    myCallback(e) {
      switch (this.type) {
        case "voided":
          this.getVoidedOrdersPage(e);
          break;
        case "closed":
          this.getClosedOrdersPage(e);
          break;
        case "all":
          this.getAllPage(e);
          break;
        default:
          this.getOpenOrdersPage(e);
          break;
      }
    },
    setPerPage(value) {
      this.per_page = value;
      this.getOpenOrders();
    },
    updateSelected(e) {
      let item_id = event.target.value;
      if (event.target.checked) {
        this.selected.push(item_id);
      } else {
        this.selected = this.selected.filter((el) => el != item_id);
      }
      this.selected_items = this.selected.join(",");
    },
    removeItem(item) {
      this.selected = this.selected.filter((el) => el != item);
      console.log(this.selected);
    },
    mergeConfirm() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        ticket_ids: this.selected_items,
      };
      TicketService.mergeTicket(formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success("Tickets merged successfully!", {
              autoClose: 3000,
            });
            this.getOpenOrders();
            this.selected = [];
            this.selected_items = "";
            this.$store.commit("SET_LOADING", false);
          } else {
            toast.error("Error merging tickets!", {
              autoClose: 3000,
            });
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    sortBy(field_name) {
      console.log("Sorting...", field_name);
      switch (field_name) {
        case "amount":
          this.resources = this.resources.sort((a, b) =>
            this.sort_order ?
              a.total_price - b.total_price :
              b.total_price - a.total_price
          );
          break;
        case "date":
          this.resources = this.resources.sort((a, b) =>
            this.sort_order ?
              new Date(a.create_date).getTime() - new Date(b.create_date).getTime() :
              new Date(b.create_date).getTime() - new Date(a.create_date).getTime()
          );
          console.log("here sorting");
          break;

        default:
          break;
      }
      this.sort_order = !this.sort_order;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    voidTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.id;
      let proceed = confirm(
        "Are you sure you want to void? This action cannot be undone!"
      );
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.voidTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.preview_receipt_modal = false;
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket voided!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error voiding ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    giftTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.id;
      let proceed = confirm(
        "Are you sure you want to gift? This action cannot be undone!"
      );
      console.log("method");
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.giftTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket gifted!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error gifting Ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    closeTicket(selected_item) {
      this.editedItem = selected_item;
      let ticket_id = this.editedItem.id;
      let proceed = confirm(
        "Are you sure you want to close? This action cannot be undone!"
      );
      console.log("method");
      if (proceed) {
        this.$store.commit("SET_LOADING", true);
        TicketService.closeTicket(ticket_id).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket closed!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error closing ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      }
    },
    goToPOS() {
      this.$router.push("/pos");
    },
    displayStatus(status) {
      let badge = "";
      switch (status) {
        case "OPEN":
          badge = "success lighten-1 text-capitalize success--text";
          break;
        default:
          badge = "secondary lighten-1 text-capitalize  secondary--text";
          break;
      }
      return badge;
    },
    onProgress(e) {
      console.log(e);
    },
    hasStartedGeneration() { },
    hasGenerated(e) {
      console.log(e);
    },
    payReceipt(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      let amount = prompt("Enter Amount");
      if (amount > 0) {
        this.editedItem.amount = amount;
        this.editedItem.mode = "CASH";
        this.editedItem.ticket_id = this.editedItem.id;
        TicketService.payTicket(this.editedItem).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket updated!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              toast.error("Error updating ticket!", {
                autoClose: 2000,
              });
              this.$store.commit("SET_LOADING", false);
            }
            // this.$router.go("/");
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.$store.commit("SET_LOADING", false);
      }
    },
    payReceiptMpesa(selected_item) {
      this.editedItem = selected_item;
      let amount = prompt("Enter Amount");
      if (amount > 0) {
        this.$store.commit("SET_LOADING", true);
        this.editedItem.amount = amount;
        this.editedItem.mode = "MPESA";
        this.editedItem.ticket_id = this.editedItem.id;
        TicketService.payTicket(this.editedItem).then(
          (response) => {
            if (response.data.code == 200) {
              this.getOpenOrders();
              this.$store.commit("SET_LOADING", false);
              toast.success("Ticket updated!", {
                autoClose: 2000,
              });
            } else {
              console.log(response.data.error);
              this.$store.commit("SET_LOADING", false);
              toast.error("Error updating ticket!", {
                autoClose: 2000,
              });
            }
            // this.$router.go("/");
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.$store.commit("SET_LOADING", false);
      }
    },
    changeCustomer(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      this.editedItem.customer_id = this.selected_customer;
      this.editedItem.ticket_id = this.editedItem.id;
      TicketService.change(this.editedItem).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Ticket updated!", {
              autoClose: 2000,
            });
          } else {
            console.log(response.data.error);
            toast.error("Error updating ticket!", {
              autoClose: 2000,
            });
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    printReceipt(selected_item) {
      console.log(selected_item);
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.name;
      this.formData.date = this.formatDate(this.editedItem.create_date);
      this.formData.reference = "BL" + this.editedItem.id;
      this.formData.created_by = this.editedItem.owner.first_name;
      this.formData.address_line_1 = this.user.company.address_line1;
      this.formData.address_line_2 = this.user.company.address_line2;
      this.formData.address_line_3 = this.user.company.address_line3;
      this.formData.ticket_items = JSON.stringify(
        selected_item.items.map((el) => {
          return {
            name: el.item_name,
            price: el.item_price,
            quantity: el.item_quantity,
            subtotal: el.item_quantity * el.item_price,
            vat: 0,
          };
        })
      );
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.sub_total;
      this.formData.paid = this.editedItem.paid_amount;
      this.formData.due = this.editedItem.due_amount;
      this.formData.tax = this.editedItem.total_tax;
      this.formData.total_amount = this.editedItem.total_price;
      this.formData.printer_ip = "127.0.0.1";
      this.formData.vat = "127";
      this.formData.pin = "A8797897Q";
      let company = JSON.parse(localStorage.getItem("user")).company;
      if (company.type == "supermarket") {
        this.editedItem.items = this.editedItem.items.map((el) => {
          return {
            name: el.item_name,
            price: el.item_price,
            quantity: el.item_quantity,
            subtotal: el.total_price,
          };
        });
        OrderService.printReceiptSuper(this.formData).then(
          (response) => {
            toast.success("Receipt printed!", {
              autoClose: 2000,
            });
            console.log(response);
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            toast.error(
              error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(), { autoClose: 3000 }
            );
            console.log(error);
          }
        );
      } else {
        OrderService.printReceipt(this.formData).then(
          (response) => {
            toast.success("Receipt printed!", {
              autoClose: 2000,
            });
            console.log(response);
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            toast.error(
              error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(), { autoClose: 3000 }
            );
            console.log(error);
          }
        );
      }
    },
    printBill(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      console.log(this.editedItem);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.name;
      this.formData.date = this.formatDate(this.editedItem.create_date);
      this.formData.reference = "BL" + this.editedItem.id;
      this.formData.created_by = this.editedItem.owner.first_name;
      this.formData.address_line_1 = this.user.company.address_line1;
      this.formData.address_line_2 = this.user.company.address_line2;
      this.formData.address_line_3 = this.user.company.address_line3;
      this.formData.ticket_items = JSON.stringify(
        this.editedItem.items.map((el) => {
          return {
            name: el.item_name,
            price: el.item_price,
            quantity: el.item_quantity,
            subtotal: el.item_quantity * el.item_price,
            vat: 0,
          };
        })
      );
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.sub_total;
      this.formData.paid = this.editedItem.paid_amount;
      this.formData.due = this.editedItem.due_amount;
      this.formData.tax = this.editedItem.total_tax;
      this.formData.total_amount = this.editedItem.total_price;
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printBill(this.formData).then(
        (response) => {
          toast.success("Print success!", {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
            (error.response && error.response.data) ||
            error.mesage ||
            error.toString(), { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    printOrder(selected_item) {
      this.$store.commit("SET_LOADING", true);
      this.editedItem = selected_item;
      console.log(this.editedItem);
      this.formData.os = "windows";
      this.formData.restaurant_name = this.user.company.name;
      this.formData.date = this.formatDate(this.editedItem.create_date);
      this.formData.reference = "BL" + this.editedItem.id;
      this.formData.created_by = this.editedItem.owner.first_name;
      this.formData.address_line_1 = this.user.company.address_line1;
      this.formData.address_line_2 = this.user.company.address_line2;
      this.formData.address_line_3 = this.user.company.address_line3;
      this.formData.ticket_items = JSON.stringify(
        this.editedItem.items.map((el) => {
          return {
            name: el.item_name,
            price: el.item_price,
            quantity: el.item_quantity,
            subtotal: el.item_quantity * el.item_price,
            vat: 0,
          };
        })
      );
      this.formData.footer = "Thank you and welcome again!";
      this.formData.sub_total = this.editedItem.sub_total;
      this.formData.tax = this.editedItem.total_tax;
      this.formData.total_amount = this.editedItem.total_price;
      this.formData.printer_ip = "127.0.0.1";
      OrderService.printOrder(this.formData).then(
        (response) => {
          toast.success("Print success!", {
            autoClose: 2000,
          });
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          this.$store.commit("SET_LOADING", false);
          toast.error(
            error.response.data.message ||
            (error.response && error.response.data) ||
            error.mesage ||
            error.toString(), { autoClose: 3000 }
          );
          console.log(error);
        }
      );
    },
    generateReport(name) {
      this.report_name = name;
      setTimeout(() => {
        var printable_content = document.getElementById("printable_content");
        var pdf = document.getElementById("printarea");
        pdf.innerHTML = printable_content.innerHTML;
        this.$refs.html2Pdf.generatePdf();
      }, 100);
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getCustomers() {
      return CustomerService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.customers = response.data.data;
          } else {
            this.customers = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getAllOrders() {
      this.type = "all";
      this.records = 0;
      this.per_page = 25;
      this.current_page = 0;
      this.$store.commit("SET_LOADING", true);
      return OrdersService.all(this.per_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getOpenOrders() {
      this.type = "open";
      this.records = 0;
      this.per_page = 25;
      this.current_page = 0;
      this.$store.commit("SET_LOADING", true);
      return OrdersService.open(this.per_page).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getVoidedOrders() {
      this.formData.type = "voided";
      this.formData.records = 0;
      this.formData.per_page = 25;
      this.formData.current_page = 0;
      this.$store.commit("SET_LOADING", true);
      return OrdersService.voided(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getClosedOrders() {
      this.formData.type = "closed";
      this.formData.records = 0;
      this.formData.per_page = 25;
      this.formData.current_page = 0;
      this.$store.commit("SET_LOADING", true);
      return OrdersService.closed(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    openByCount(per_page) {
      //pass selected page param
      console.log("Per page ::: ", per_page);
      this.$store.commit("SET_LOADING", true);
      return OrdersService.openByCount(per_page).then(
        //edit page to selected page
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getOpenOrdersPage(page_number) {
      console.log("Per page ::: ", page_number);
      this.$store.commit("SET_LOADING", true);
      let data = {
        per_page: this.per_page,
        page_number: page_number,
      };
      return OrdersService.openPage(data).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getClosedOrdersPage(page_number) {
      console.log("Per page ::: ", page_number);
      this.$store.commit("SET_LOADING", true);
      let data = {
        per_page: this.per_page,
        page_number: page_number,
      };
      return OrdersService.closedPage(data).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getVoidedOrdersPage(page_number) {
      console.log("Per page ::: ", page_number);
      this.$store.commit("SET_LOADING", true);
      let data = {
        per_page: this.per_page,
        page_number: page_number,
      };
      return OrdersService.voidedPage(data).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllPage(page_number) {
      console.log("Per page ::: ", page_number);
      this.$store.commit("SET_LOADING", true);
      let data = {
        per_page: this.per_page,
        page_number: page_number,
      };
      return OrdersService.allPage(data).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data.data;
            this.records = response.data.data.total;
            this.current_page = response.data.data.current_page;
            this.next_page_url = response.data.data.next_page_url;
            this.prev_page_url = response.data.data.prev_page_url;
            this.first_page_url = response.data.data.first_page_url;
            this.last_page_url = response.data.data.last_page_url;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    editItem(item) {
      this.editedItem = item;
      console.log(this.editedItem);
    },
    previewReceiptModal(order) {
      this.editedItem = Object.assign({}, order);
      this.editedIndex = this.resources.indexOf(order);
      this.cart = Object.assign({}, order.order);
      this.totalValue = order.total_price;
      this.editedItem.ticket_id = order.id;
    },
    changeCustomerModal() {
      this.change_customer_modal = true;
    },
    viewItem(item) {
      this.editedIndex = this.resources.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>
